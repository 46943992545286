import React from 'react';
import { useNavigate } from 'react-router-dom';

const CrosswordArchive = () => {
  const navigate = useNavigate();

  const puzzles = [
    {
      id: "679a3d70",
      set: "7e2323f3279fd31f6f309e83e6db818a58e42e30b15bd36a68e2aa9490716e19",
      title: "Snuggles"
    },
    {
      id: "d780e350",
      set: "7e2323f3279fd31f6f309e83e6db818a58e42e30b15bd36a68e2aa9490716e19",
      title: "Magic Wand"
    },
    {
      id: "754741ca",
      set: "7e2323f3279fd31f6f309e83e6db818a58e42e30b15bd36a68e2aa9490716e19",
      title: "Sunset"
    },
    {
      id: "9e7fb966",
      set: "7e2323f3279fd31f6f309e83e6db818a58e42e30b15bd36a68e2aa9490716e19",
      title: "Appetite"
    },
    {
      id: "2b478e05",
      set: "7e2323f3279fd31f6f309e83e6db818a58e42e30b15bd36a68e2aa9490716e19",
      title: "Masses"
    },
    {
      id: "45d80eb7",
      set: "7e2323f3279fd31f6f309e83e6db818a58e42e30b15bd36a68e2aa9490716e19",
      title: "Lapses"
    },
    {
      id: "031f2986",
      set: "7e2323f3279fd31f6f309e83e6db818a58e42e30b15bd36a68e2aa9490716e19",
      title: "Red"
    },
    {
      id: "4b14bbf4",
      set: "7e2323f3279fd31f6f309e83e6db818a58e42e30b15bd36a68e2aa9490716e19",
      title: "Culture"
    },
    {
      id: "23ce84b5",
      set: "7e2323f3279fd31f6f309e83e6db818a58e42e30b15bd36a68e2aa9490716e19",
      title: "Rains"
    },
    {
      id: "12cc190e",
      set: "7e2323f3279fd31f6f309e83e6db818a58e42e30b15bd36a68e2aa9490716e19",
      title: "Again"
    },
    {
      id: "aa66bff2",
      set: "7e2323f3279fd31f6f309e83e6db818a58e42e30b15bd36a68e2aa9490716e19",
      title: "Challenge"
    },
    {
      id: "96c2f426",
      set: "7e2323f3279fd31f6f309e83e6db818a58e42e30b15bd36a68e2aa9490716e19",
      title: "Bits"
    }

  ];

  const handlePuzzleClick = (puzzleId) => {
    navigate(`/crossword/${puzzleId}`);
  };

  return (
    <div className="w-full min-h-screen bg-[#F6F5F1]">
      <div className="max-w-5xl mx-auto px-4 sm:px-6 lg:px-8 py-4 sm:py-6 lg:py-8">
        {/* Header Section */}
        <div className="text-center mb-6 sm:mb-8 lg:mb-12 border-b-2 border-black pb-4 sm:pb-6 lg:pb-8">
          <h1 className="font-serif text-3xl sm:text-4xl lg:text-5xl mb-2 sm:mb-4 tracking-tight">
            Word Garden
          </h1>
          <div className="flex flex-col sm:flex-row items-center justify-center text-xs sm:text-sm space-y-2 sm:space-y-0 sm:space-x-4 uppercase tracking-widest">
            <span>Est. 2024</span>
            <span className="hidden sm:block w-1 h-1 bg-black rounded-full"></span>
            <span>Daily Puzzles</span>
            <span className="hidden sm:block w-1 h-1 bg-black rounded-full"></span>
            <span>Volume I</span>
          </div>
        </div>

        {/* Puzzles Grid */}
        <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 sm:gap-6 lg:gap-8">
          {puzzles.map((puzzle) => (
            <div
              key={puzzle.id}
              onClick={() => handlePuzzleClick(puzzle.id)}
              className="group cursor-pointer bg-white border border-black/10 hover:border-black transition-all duration-300"
            >
              <div className="p-4 sm:p-6">
                {/* Date and Difficulty */}
                <div className="flex justify-between items-center text-xs uppercase tracking-widest mb-3 sm:mb-4 text-gray-600">
                  <span className="hidden sm:block">

                  </span>
                  <span className="sm:hidden">

                  </span>

                </div>

                {/* Title */}
                <h2 className="font-serif text-xl sm:text-2xl mb-3 sm:mb-4 group-hover:text-gray-700 transition-colors">
                  {puzzle.title}
                </h2>

                {/* Decorative Line */}
                <div className="w-12 h-px bg-black mb-3 sm:mb-4 group-hover:w-full transition-all duration-300"></div>

                {/* Time and CTA */}
                <div className="flex justify-between items-end">
                  <div className="flex items-center text-xs sm:text-sm text-gray-600">


                  </div>
                  <button
                    className="text-xs sm:text-sm uppercase tracking-widest hover:text-gray-600 transition-colors
                             border-b border-transparent hover:border-gray-600 pb-1"
                  >
                    Solve Puzzle →
                  </button>
                </div>
              </div>
            </div>
          ))}
        </div>

        {/* Footer Quote */}

      </div>
    </div>
  );
};

export default CrosswordArchive;